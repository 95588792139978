<template>
  <div class="pageContainer">
    <el-card shadow="never">
      <div slot="header">
        <el-form :inline="true">
          <el-form-item label="状态">
            <el-select v-model="query.status" clearable>
              <el-option v-for="item in STATUS_MAP" :label="item.label" :key="item.key" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="queryData">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-row type="flex" justify="start">
        <el-button type="primary" @click="handleClickAdd">新增扶持记录</el-button>
      </el-row>
      <div class="tableWrap">
        <el-table :data="tableData"
                  border
                  v-loading="loadingList"
                  row-key="id">
          <el-table-column fixed prop="companyName" label="公司名称" min-width="100">
            <template v-slot="{ row }">
              <router-link :to="{name: 'supportRecordDetail', params: { supportRecordId: row.id }}"><el-button type="text">{{row.companyName}}</el-button></router-link>
            </template>
          </el-table-column>
          <el-table-column prop="opusName" label="作品名称"></el-table-column>
          <el-table-column label="状态" min-width="80">
            <template v-slot="{ row }">
              <span>{{row.status | caseStatus}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="contact" label="联系人"></el-table-column>
          <el-table-column prop="mobile" label="联系人手机号"></el-table-column>
          <el-table-column prop="type" label="作品类型"></el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="120">
            <template v-slot="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
              <el-button type="text" @click="handleEdit(row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
          :current-page.sync="pageObj.currentPage"
          :total="pageObj.total"
          :page-sizes="pageObj.pageSizes"
          :page-size="pageObj.pageSize"
          @current-change = "handlerPageChange"
          @size-change = "handlerSizeChange"
          :layout="pageObj.layout"></el-pagination>
    </el-card>
  </div>
</template>

<script>
import globalConfig from 'config/global'
import { execute, STATUS_MAP } from './option'
import { mapActions } from 'vuex'
export default {
  name: 'supportRecordList',
  data() {
    return {
      STATUS_MAP,
      query: {
        status: 'NEW'
      },
      tableData: [],
      loadingList: false,
      pageObj: {
        ...globalConfig.pagination,
        currentPage: 1,
        total: 0
      }
    }
  },
  created () {
    this.getListByPage()
  },
  methods: {
    ...mapActions(['getSupportRecordList', 'deleteSupportRecord']),
    // 新增
    handleClickAdd() {
      execute('addOrEditSupportRecord', {
        optType: 'add',
        callback: this.getListByPage
      })
    },
    queryData() {
      this.getListByPage(1)
    },
    getListByPage() {
      this.loadingList = true
      this.getSupportRecordList({
        page: this.pageObj.currentPage,
        size: this.pageObj.pageSize,
        ...this.query
      }).then(res => {
        console.log(res)
        const { recordList, total } = res
        this.tableData = recordList || []
        this.pageObj.total = total
      }).finally(() => {
        this.loadingList = false
      })
    },
    handlerPageChange(page) {
      this.pageObj.currentPage = page
      this.getListByPage()
    },
    handlerSizeChange(size) {
      this.pageObj.pageSize = size
      this.getListByPage()
    },
    handleDelete(row) {
      this.$confirm('确认删除该供应商吗？').then(() => {
        this.deleteSupportRecord(row.id).then(() => {
          this.$message.success('操作成功')
        }).finally(() => {
          this.getListByPage()
        })
      })
    },
    handleEdit(row) {
      console.log(row)
      execute('addOrEditSupportRecord', {
        optType: 'edit',
        detail: { ...row },
        callback: this.getListByPage
      })
    }
  }
}
</script>

<style scoped>
.pageContainer {
  padding: 20px;
}
.tableWrap {
  margin: 20px 0;
}
.displayImg {
  display: block;
  max-width: 120px;
}
</style>
